import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class ICanNotReact extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`,
          `react`, `frontend`, `front-end`, `webdeisgn`,
          `webdevelop`, `webdeveloper`, `programming`, `coding`,
          `portfolio`, `reactjs`, `ui`, `ux`, `node`, `nodejs`, `expressjs`
        ]}
        />
        <p>Online Version of <span style={{textTransform: "uppercase", fontWeight: "bold", fontSize: "1.5rem"}}>I Can Not React</span> Book. Check it out <a href="https://github.com/KirankumarAmbati/I-can-not-REACT" target="_blank">here</a>.</p>
      </Layout>
    )
  }
}

export default ICanNotReact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`

